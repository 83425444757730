@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* TODO: REMOVE GOOGLE FONTS IMPORT */
@import url("https://fonts.googleapis.com/css2?family=Saira+Condensed&display=swap");

:root {
  --youtube-red: #fe0900;
  --yellow: #ffc000;
}
html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

body {
  @apply w-full h-full;
  @apply flex items-center justify-center;
  @apply bg-black;
  @apply overflow-hidden;
  font-family: "Saira Condensed", sans-serif;
}

.sdtv-debug {
  @apply absolute top-1 left-1;
  @apply px-px py-px;
  @apply bg-white;
  @apply rounded-sm;
  @apply shadow;
  @apply text-xs;
}

#sdtv-player-debug-buttons {
  @apply absolute top-6 right-1;
  @apply text-white;
  @apply bg-black;
  @apply rounded;
  @apply px-2;
}

.sdtv-error {
  @apply w-full h-full;
  @apply flex items-center justify-center;
  & .sdtv-error-content {
    @apply bg-[#211F1A];
    @apply max-w-xs;
    @apply max-h-[90%];
    @apply overflow-y-auto;
    @apply text-white;
    @apply rounded;
    @apply p-4;
    & .sdtv-error-message {
      @apply text-lg;
      @apply pl-12;
      @apply min-h-[2rem];
      @apply flex items-center;
      background: left center / 2rem no-repeat #211f1a
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    }
    & .sdtv-error-button-wrapper {
      @apply mt-4;
      @apply flex items-center justify-center;
    }
  }
}


.sdtv-player-container-small > .sdtv-error > .sdtv-error-content {
  & .sdtv-error-message {
    padding-top: 2.5rem;
    padding-left: 0;
    background: top center / 1.5rem no-repeat #211f1a url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    @apply line-clamp-3;
    @apply text-sm;
  }
  & .sdtv-button {
    @apply text-center;
  }
}


.sdtv-button {
  @apply bg-youtube;
  @apply text-white text-sm;
  @apply cursor-pointer;
  @apply rounded-sm;
  @apply px-4 py-1;
}

sdtv-player,
sdtv-mux {
  @apply w-full h-full;
  @apply w-screen h-screen;
}

video {
  @apply w-full h-full;
  @apply pointer-events-none;
  @apply object-contain;
}

.sdtv-player-container,
.sdtv-player-container-big {
  @apply mx-auto;
  @apply w-full h-full max-h-fit;
  @apply relative;
  /* @apply aspect-w-16 aspect-h-9; */
  & video {
    /* @apply absolute left-0 right-0; */
  }
  &[data-conference="true"] {
    & .sdtv-controls {
      & .sdtv-bottom-controls {
        @apply bottom-10;
      }
    }
  }
}

.sdtv-conference {
  @apply flex items-end justify-center;
  @apply w-full h-full;
  @apply w-screen h-screen;
  & .sdtv-conference-backdrop {
    @apply fixed top-0 left-0 right-0 bottom-0;
    @apply bg-black bg-opacity-80;
    @apply z-[15];
    @apply transition-opacity ease-in duration-700 opacity-0;
    @apply blur-sm;
    & .sdtv-conference-background {
      @apply opacity-20;
    }
  }
  & .sdtv-conference-button {
    @apply fixed bottom-4 left-20;
    @apply bg-youtube;
    @apply text-white;
    @apply uppercase;
    @apply z-[21];
    @apply text-xs;
    @apply cursor-pointer;
    @apply rounded-sm;
    @apply px-2 py-px;
  }

  & .sdtv-player-container-small {
    @apply relative;
    /* @apply max-w-[33.333333%]; */
    @apply min-w-[33.333333%];
    @apply flex-1;
    @apply transform translate-y-full;
    @apply transition-transform;
    @apply mx-2 mb-8;
    @apply z-20;
    @apply pointer-events-none;
    @apply cursor-pointer;

    & video {
      @apply rounded-lg;
      @apply overflow-hidden;

      &#sdtv-player-mux-active {
        @apply border-2 border-youtube;
      }
    }
    & .sdtv-video-title {
      @apply text-white text-sm;
      @apply pt-2;
      @apply line-clamp-3;
    }
  }
  & .sdtv-conference-assets {
    @apply flex flex-col items-start justify-start mx-16 px-4;
    @apply w-full max-w-full;
    & .sdtv-conference-assets-wrapper {
      @apply flex items-end justify-start;
      @apply max-w-full;
      @apply overflow-x-auto overflow-y-hidden;
    }
  }
  &[data-open="true"] {
    @apply items-center justify-center;
    & .sdtv-player-container-small {
      @apply transform-none;
      @apply mb-0;
      @apply pointer-events-auto;
    }
    & .sdtv-conference-backdrop {
      @apply opacity-100;
    }
    & .sdtv-conference-button {
      @apply relative left-0 bottom-0;
      @apply ml-2 mb-2;
    }
    & .sdtv-conference-assets {
      & .sdtv-conference-assets-wrapper {
        @apply items-start;
        &[data-amount="1"],
        &[data-amount="2"],
        &[data-amount="3"] {
          & .sdtv-player-container-small {
            @apply min-w-0;
          }
        }
      }
    }
  }
  &[data-open="false"] {
    & .sdtv-conference-assets {
      & .sdtv-conference-assets-wrapper {
        &[data-amount="1"],
        &[data-amount="2"],
        &[data-amount="3"],
        &[data-amount="4"] {
          & .sdtv-player-container-small {
            @apply min-w-0;
          }
        }
      }
    }
  }
}

.sdtv-player-container-small {
  @apply z-10;
}

.sdtv-player-container-big {
  @apply fixed top-0 left-0 right-0 bottom-0;
  @apply h-screen;
}

.sdtv-player-element {
  @apply w-full;
  border-radius: 100%;
}

.sdtv-big-controls {
  @apply absolute top-0 left-0 right-0 bottom-0;
  @apply transition-opacity;
  @apply flex items-center justify-center;
  @apply px-4;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5)
  );
  & .sdtv-big-controls-buttons {
    @apply h-full;
    @apply flex items-center justify-around;
    @apply max-w-md;
    @apply w-full;
  }
  & .sdtv-icon-big {
    @apply w-24 h-24;
    max-height: 20vh;
    @apply cursor-pointer;
    &.sdtv-icon-big-play,
    &.sdtv-icon-big-pause {
    }
    &.sdtv-icon-big-forward,
    &.sdtv-icon-big-back {
      opacity: 0.5;
    }
  }
}

.sdtv-top-controls {
  @apply absolute left-0 top-0 right-0;
  @apply p-4;
  @apply flex items-start justify-between;
  & svg {
    @apply transform translate-y-1.5;
  }

  & .sdtv-top-controls-close {
    & svg {
      @apply flex-shrink-0;
    }
  }
  & .sdtv-top-controls-title-wrapper {
    @apply flex-1;
    @apply truncate;
    & .sdtv-top-controls-title {
      @apply text-[22px] leading-[24px];
      @apply text-white;
      @apply truncate;
    }
    & .sdtv-top-controls-sub-title {
      @apply text-[18px] leading-[21px];
      @apply text-[#8F8F8F];
      @apply truncate;
    }
  }
  & .sdtv-top-controls-actions {
    @apply flex items-center space-x-[24px];
    @apply ml-4;
    & svg {
      @apply w-[24px] h-[24px];
    }
  }
}

.sdtv-controls {
  @apply absolute left-0 right-0 bottom-0;
  @apply text-white;
  @apply px-4;
  transition: all 0.2s ease;

  & .sdtv-bottom-controls {
    @apply absolute bottom-5 left-0 right-0;
    @apply flex items-end justify-between;

    & .sdtv-bottom-controls-left,
    & .sdtv-bottom-controls-right {
      @apply h-[20px];
      @apply mx-4;
      @apply flex items-center space-x-4;
      & .sdtv-icon {
        @apply w-auto h-[18px];
        &.sdtv-icon-pip {
          @apply w-[23px] h-auto;
        }
      }
    }

    & .sdtv-bottom-controls-middle {
      @apply flex-1;
      @apply ml-4;

      & .sdtv-controls-time {
      }
      & .sdtv-controls-progress {
        @apply relative;
        @apply h-[20px];

        & input,
        & progress {
          @apply top-1/2;
          @apply transform -translate-y-1/2;
        }
      }
    }
  }
}

.sdtv-bottom-controls-left {
  display: flex;
  align-items: center;
  color: #fff;
}

.sdtv-controls.hide {
  opacity: 0;
  pointer-events: none;
}

.sdtv-controls-progress {
}

progress {
  --progress-height: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @apply rounded-full;
  @apply w-full;
  @apply h-[var(--progress-height)];
  @apply pointer-events-none;
  @apply absolute top-0;

  &::-webkit-progress-bar {
    background-color: #474545;
    @apply rounded-full;
  }
  &::-webkit-progress-value {
    @apply bg-youtube;
    @apply rounded-full;
  }
  &::-moz-progress-bar {
    border: 1px solid var(--youtube-red);
    @apply bg-youtube;
  }
}

.sdtv-volume-controls {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.sdtv-volume-controls input {
  width: 100px;
  opacity: 1;
  transition: all 0.4s ease;
}

.sdtv-volume-controls:hover input,
.sdtv-volume-controls input:focus {
  width: 100px;
  opacity: 1;
}

input[type="range"] {
  --progress-height: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  @apply h-[var(--progress-height)];
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
    &::-webkit-slider-runnable-track {
      background: transparent;
    }
    &::-moz-range-track {
      outline: none;
    }
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 1.3px;
    -webkit-appearance: none;
    transition: all 0.4s ease;
  }
  &::-moz-range-track {
    @apply w-full;
    @apply h-[var(--progress-height)];
    @apply cursor-pointer;
    @apply border-none;
    @apply bg-transparent;
    @apply rounded-full;
  }
  &::-webkit-slider-thumb {
    @apply w-[20px] h-[20px];
    @apply rounded-full;
    @apply bg-youtube;
    @apply cursor-pointer;
    -webkit-appearance: none;
    @apply -ml-px;
  }
  &::-moz-range-thumb {
    @apply w-[20px] h-[20px];
    @apply rounded-full;
    @apply border-none;
    @apply bg-youtube;
    @apply cursor-pointer;
    margin-top: 5px;
  }

  &.sdtv-volume {
    height: 5px;
    background-color: #fff;

    &::-webkit-slider-runnable-track {
      background-color: transparent;
    }

    &::-webkit-slider-thumb {
      margin-left: 0;
      height: 14px;
      width: 14px;
      background: #fff;
    }
    &::-moz-range-thumb {
      border: 1px solid #fff;
      background: #fff;
    }
  }
}

.sdtv-controls-seek {
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
}

svg {
  @apply w-6 h-6;
  @apply fill-white stroke-white;
}

.sdtv-icon {
  border: none;
  outline: none;
  background-color: transparent;
  flex-shrink: 0;
}

.sdtv-icon-airplay-active {
  stroke: var(--yellow);
  fill: var(--yellow);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sdtv-loading-wrapper {
  @apply flex items-center justify-center;
  @apply fixed left-0 top-0 right-0 bottom-0;
  @apply w-full h-full;
  & .sdtv-loading-image {
    @apply absolute;
    @apply max-w-full max-h-full;
    @apply w-auto h-auto;
  }
  & .sdtv-loading {
    content: "";
    display: inline-block;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left-color: var(--yellow);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: loading 1.2s linear infinite;
    @apply m-4;
    position: absolute;
  }

  &.sdtv-loading-wrapper-error {
    @apply flex-col;
    & .sdtv-error {
      @apply z-[2];
      & .sdtv-error-content {
        & .sdtv-error-message {
          @apply relative;
          @apply bg-none;
        }
      }
    }
    & .sdtv-loading-wrapper {
      @apply w-8 h-8;
      @apply rounded-full;
      @apply relative;
      @apply absolute;
      @apply left-0 top-1/2;
      @apply transform -translate-y-1/2;
      & .sdtv-loading {
        @apply m-0;
        @apply w-8 h-8;
      }
    }
  }
}

mux-player {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  pointer-events: none;
}

#ad-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
